import { AuthPage } from "@refinedev/antd";

export default function Login() {
  return (
    <AuthPage
      type="login"
      title={''}
      registerLink={""} forgotPasswordLink={""}
    />
  );
}
